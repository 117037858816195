import React from "react";
import styled from "styled-components";
import Button from "./elements/Button";

const CityCard = ({ data, setCity, setStep }) => {
  return (
    <Container>
      <div className="room-img">
        <img src={data.image} alt={data.name} />
        <div className="img-overlay">
          <div className="img-overlay-content">
            <h4> {data.name}</h4>
            <Button
              handleClick={() => {
                setCity(data);
                setStep(1);
              }}
              title="Choisir"
              color="#009685"
              radius="4px"
              margin="0.75em"
            />
          </div>
        </div>
      </div>
      <div className="room-name">
        <span>{data.name}</span>
      </div>
    </Container>
  );
};

export default CityCard;
const Container = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-rows: 230px 30px;
  &:hover {
    .room-img {
      border: 3px solid #009685;
      transition: border 0.1s ease-in;
    }
    .room-name {
      display: none;
    }
    .img-overlay {
      height: calc(100% - 10px) !important;
      transition: height 0.3s ease-in;
    }
  }
  .room-img {
    transition: border 0.1s ease-in-out;
    border-radius: 18px;
    padding: 5px;
    position: relative;
    img {
      width: 100%;
      height: 215px;
      border-radius: 16px;
      overflow: hidden;
    }
    .img-overlay {
      position: absolute;
      top: 5px;
      left: 5px;
      border-radius: 16px;
      overflow: hidden;
      width: calc(100% - 10px);
      height: 0;
      display: flex;
      .img-overlay-content {
        background: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        p {
          margin: 0.25em 0;
        }
        h4 {
          font-size: 1.2rem;
        }
      }
    }
  }
  .room-name {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #202862;
    }
  }

  @media only screen and (max-width: 768px) {
  }
`;
