import { useState, useEffect } from "react";

const steps = {
  0: "Choissisez une ville",
  1: "Réservé Une Salle  ",
  2: "General",
  3: "Choissisez une date",
  4: "Votre demande à été envoyer",
  5: "Devis",
  6: "Paiement",
  profile: "Votre profile",
};

const usePageTitle = (step) => {
  const [title, setTitle] = useState(null);
  let mounted = true;

  useEffect(() => {
    if (mounted) {
      setTitle(steps[step]);
    }
    return () => {
      mounted = false;
    };
  });

  return title;
};

export default usePageTitle;
