import React, { createContext, useState, useEffect } from "react";

const PriceContext = createContext({});

export const PriceProvider = ({ children }) => {
  const [price, setPrice] = useState(0);

  function isWeekend(date = new Date()) {
    return date.getDay() === 6 || date.getDay() === 0;
  }

  const getPriceByUser = (userType, room, time) => {
    if (userType === "interne" && room.type == "Réunion") {
      if (time == 1) return Number(room.tarif_two_heures);
      if (time == 2) return Number(room.tarif_four_heures);
      if (time == 3) return Number(room.tarif_eight_heures);
    }
    if (userType === "entreprise") {
      if (time == 4) return Number(room.tarif_entre_jour);
      return Number(room.tarif_entre_demi_jour);
    } else {
      if (time == 4) return Number(room.tarif_ass_jour);
      return Number(room.tarif_ass_demi_jour);
    }
  };

  const calculePrice = (values, room, user) => {
    const userType = user.type_compte;
    const dateOfReservation = values.fullDate[0];
    let price = getPriceByUser(userType, room, values.time);
    if (room.type != "Réunion") {
      if (isWeekend(dateOfReservation)) {
        price = Number((price * 20) / 100 + Number(price));
      }
    }
    setPrice(price);

    return price;
  };

  const priceContext = {
    price,
    calculePrice,
    setPrice,
  };

  return (
    <PriceContext.Provider value={priceContext}>
      {children}
    </PriceContext.Provider>
  );
};

export const PriceConsumer = PriceContext.Consumer;

export default PriceContext;
