import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Account from "./pages/Account";
import Reservations from "./pages/Reservations";
import Signup from "./pages/Signup";
import Verification from "./pages/Verification";
import ReservationsDetails from "./pages/ReservationsDetails";
import { AdminProvider } from "./contexts/AdminContext";
import { StepProvider } from "./contexts/StepContext";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const App = () => {
  return (
    <AdminProvider>
      <StepProvider>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path={["/", "/dashboard"]} component={Dashboard} />
            <Route exact path="/liste-reservations" component={Reservations} />
            <Route exact path="/profile" component={Account} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/verification" component={Verification} />
            <Route
              exact
              path="/reservations-details"
              component={ReservationsDetails}
            />
          </Switch>
        </Router>
      </StepProvider>
    </AdminProvider>
  );
};

export default App;
