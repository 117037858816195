import React, { useState, useContext } from "react";
import styled from "styled-components";
import RoomCart from "./RoomCart";
import CustomInput from "./elements/CustomInput";
import AdminContext from "../contexts/AdminContext";

const FirstStep = ({ data, status, setRoom, setStep, city, setCity }) => {
  const [filter, setFilter] = useState({ ville: city?.name, type: "" });
  const { admin } = useContext(AdminContext);
  const getFilteredData = (data) => {
    if (!filter.ville && !filter.type) return data;
    return data.filter((room) => {
      const cityMatch = room.ville
        .toLowerCase()
        .includes(filter.ville.toLowerCase());
      const typeMatch = room.type
        .toLowerCase()
        .includes(filter.type.toLowerCase());
      if (filter.ville && filter.type) return cityMatch && typeMatch;
      if (!filter.ville && filter.type) return typeMatch;
      if (filter.ville && !filter.type) return cityMatch;
      return false;
    });
  };
  const filteredData = getFilteredData(data);

  console.log(data);
  return (
    <Container>
      <div className="filter">
        <select
          value={filter.ville}
          name="ville"
          id="ville"
          onChange={(e) => setFilter({ ...filter, ville: e.target.value })}
        >
          <option value="Agadir">Agadir</option>
          <option value="Rabat">Rabat</option>
          <option value="Tanger">Tanger</option>
          <option value="Cité Innovation SM">Cité Innovation SM</option>
          <option value="Casablanca">Casablanca</option>
          <option value="">Tout</option>
        </select>

        <select
          name="type"
          id="type"
          value={filter.type}
          onChange={(e) => setFilter({ ...filter, type: e.target.value })}
        >
          <option value="formation">Salle de formation</option>
          <option value="conférence">Salle de conférence</option>
          {/* {admin?.type_compte === "interne" && (
            <option value="réunion">Salle de réunion</option>
          )} */}
          <option value="">Tout</option>
        </select>
      </div>
      <h3>Choisissez une salle :</h3>
      {status ? (
        <div className="fetching-data">
          <svg
            version="1.1"
            id="loader-1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="40px"
            height="40px"
            viewBox="0 0 50 50"
            enableBackground="new 0 0 50 50;"
          >
            <path
              fill="#000"
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      ) : (
        <div className="first-step-content">
          {filteredData?.length > 0 ? (
            filteredData.map((room, index) => {
              return (
                <RoomCart
                  data={room}
                  setRoom={setRoom}
                  setStep={setStep}
                  key={index}
                />
              );
            })
          ) : (
            <div>
              <p>Aucune salle trouvé.</p>
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

export default FirstStep;

const Container = styled.div`
  select {
    padding: 10px;
    font-size: 14px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  .filter {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  .fetching-data {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;
  }
  h3 {
    color: #202862;
    font-weight: 500 !important;
    margin-top: 1em;
  }
  .first-step-content {
    padding: 1em 0;
    width: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
  }

  @media only screen and (max-width: 1100px) {
    .first-step-content {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 600px) {
    .first-step-content {
      grid-template-columns: 100% !important;
    }
  }
`;
