import React, { useContext, useState } from "react";
import { Field, Form, Formik } from "formik";
import styled from "styled-components";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import AutoSubmit from "./auto-submit";
import { French } from "flatpickr/dist/l10n/fr";

import * as Yup from "yup";
const periodeTime = ["Matinée", "Après midi", "Soir", "Toute la journée"];

const Conference = ({ tools = [], room }) => {
  const [needs, setNeeds] = useState([]);

  const handleNeeds = (value) => {
    setNeeds([...needs, { qte: value.qte, elem: value.elem }]);
  };
  console.log(needs);
  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{
          day: "",
          time: "",
          comment: "",
          event: "",
          custom_event: "",
          thematique: "",
          qte: "",
          elem: tools[0],
        }}
        validationSchema={Yup.object({
          day: Yup.string().required("Champs Obligatoire"),
          time: Yup.string().required("Champs Obligatoire"),
          event: Yup.string().required("Champs Obligatoire"),
          thematique: Yup.string().required("Champs Obligatoire"),
          qte: Yup.string().matches(/^[0-9]*$/, "Uniquement des chiffres"),
        })}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form style={{ width: "100%" }}>
            <div className="container">
              <h3 className="title">Date de réservation:</h3>
              <div className="row-table">
                <div className="hours-container">
                  <Flatpickr
                    options={{ minDate: new Date(), locale: French }}
                    style={{ width: "100%", marginTop: 0, marginBottom: 10 }}
                    placeholder="Choissisez une date"
                    name="day"
                    onChange={(e, dateStr) => {
                      setFieldValue("day", dateStr);
                    }}
                  />
                  {errors.day && touched.day && <p>{errors.day}</p>}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Field name="time" as="select" className="hour">
                      <option value="">Séléctionnez une plage horaire</option>
                      {periodeTime.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </Field>
                  </div>
                  {errors.time && touched.time && (
                    <p className="red">{errors.time}</p>
                  )}
                  <h4>Commentaire:</h4>
                  <Field className="textarea" as="textarea" name="comment" />
                  <br />
                </div>
              </div>
              <h3 className="title">Besoins:</h3>
              <div className="row">
                <h5>Type de l&apos;évenement</h5>
                <Field as="select" name="event">
                  <option value="Conférence">Conférence</option>
                  <option value="Relation presse"> Relation presse</option>
                  <option value="Formation">Formation </option>
                  <option value="Rencontre professionnelle">
                    Rencontre professionnelle
                  </option>
                  <option value="Réunion">Réunion </option>
                  <option value="Autre">Autre </option>
                </Field>
                {values.event === "Autre" && (
                  <Field
                    as="input"
                    placeholder="Précisez votre evenement"
                    type="text"
                    name="custom_event"
                    style={{ marginLeft: 10 }}
                  />
                )}
              </div>

              <div className="row">
                <h5>Thématique de l’événement</h5>
                <Field
                  name="thematique"
                  placeholder="Thématique de l’événement"
                  type="text"
                />
              </div>
              {(errors.type && touched.type) ||
                (errors.thematique && touched.thematique && (
                  <p className="red">{errors.type || errors.thematique}</p>
                ))}
              <h3 className="title second">Besoins techniques</h3>
              <div className="grid row">
                <h5 className="label">élements</h5>
                <h5 className="label">Qté</h5>
                <h5 />
              </div>

              {needs.map((item, index) => (
                <div className="grid row" key={index}>
                  <h6>{item.elem}</h6>
                  <h6>{item.qte}</h6>
                  <h6 />
                </div>
              ))}

              <div className="grid-bottom">
                <Field as="select" name="elem">
                  {tools.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </Field>
                <div className="qte">
                  <Field placeholder="0" type="text" name="qte" />
                  {errors.qte && touched.qte && (
                    <p className="red">{errors.qte}</p>
                  )}
                </div>
                <button
                  type="button"
                  className="submit"
                  onClick={() => handleNeeds(values)}
                >
                  +
                </button>
              </div>
            </div>
            <AutoSubmit needs={needs} room={room} values={values} />
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Conference;

const Container = styled.div`
  width: 100%;

  .container {
    width: 100%;
  }
  .textarea {
    color: #838fe8;
    border-radius: 7px;
    border: 1px solid #838fe8;
    padding: 8px;
    margin-top: 0.75em;
    outline: none;
    font-size: 15px;
  }
  .textarea {
    height: 80px;
    width: 330px;
  }
  h4 {
    color: #202862;
    font-weight: 600 !important;
    margin-top: 2.25em;
  }
`;
