import React from "react";
import styled from "styled-components";
import CityCard from "./VilleCart";

const CityStep = ({ data, status, setCity, setStep }) => {
  const cityArr = [
    {
      id: 1,
      name: "Rabat",
      image:
        "https://media.tacdn.com/media/attractions-splice-spp-674x446/07/38/25/24.jpg",
    },
    {
      id: 2,
      name: "Agadir",
      image:
        "https://www.moroccoworldnews.com/wp-content/uploads/2022/05/agadir-morocco-named-cheapest-tourist-destination-this-summer-800x562.jpg",
    },
    {
      id: 3,
      name: "Tanger",
      image:
        "https://www.climamed.eu/wp-content/uploads/2021/03/Tanger-3-1-1024x578.jpg",
    },
    {
      id: 4,
      name: "Casablanca",
      image:
        "https://a.travel-assets.com/findyours-php/viewfinder/images/res70/469000/469963-Hassan-Ii-Mosque.jpg",
    },
    {
      id: 5,
      name: "Cité Innovation SM",
      image:
        "https://static.lematin.ma/files/lematin/images/articles/2021/01/87e50385bd3adb1b9ff8e59ad5ec4b79.png",
    },
  ];
  return (
    <Container>
      <h3>Choisissez une ville :</h3>
      {status ? (
        <div className="fetching-data">
          <svg
            version="1.1"
            id="loader-1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="40px"
            height="40px"
            viewBox="0 0 50 50"
            enableBackground="new 0 0 50 50;"
          >
            <path
              fill="#000"
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      ) : (
        <div className="first-step-content">
          {cityArr.map((city, index) => {
            return (
              <CityCard
                data={city}
                setCity={setCity}
                setStep={setStep}
                key={index}
              />
            );
          })}
        </div>
      )}
    </Container>
  );
};

export default CityStep;

const Container = styled.div`
  .fetching-data {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;
  }
  h3 {
    color: #202862;
    font-weight: 500 !important;
    margin-top: 1em;
  }
  .first-step-content {
    padding: 1em 0;
    width: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 1em;
  }

  @media only screen and (max-width: 1100px) {
    .first-step-content {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 600px) {
    .first-step-content {
      grid-template-columns: 100% !important;
    }
  }
`;
