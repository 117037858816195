import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import Layout from "../layouts/DefaultLayout";
import PageTitle from "../components/elements/PageTitle";

const ReservationsDetails = () => {
  const [data, setData] = useState({});
  const location = useLocation();

  const getDate = (d) => {
    const dt = new Date(d);
    const time = dt.toLocaleTimeString("en-US", { hour12: false }).split(":");

    const date = dt.toLocaleDateString("fr-CA") + " " + time[0] + "h" + time[1];

    return date;
  };

  useEffect(() => {
    if (location?.state) {
      setData(location.state);
    }
  }, [location]);

  return (
    <Layout>
      <Container>
        <PageTitle title="Détail de la réservation" />
        <div className="content">
          <div className="col">
            <h3 className="title">Information sur la réservation</h3>
            <div className="row">
              <h5>Salle choisie</h5>
              <h6>Salle {data?.roomName}</h6>
            </div>
            <div className="row">
              <h5>Dates réservée</h5>
              <h6>Salle {data?.date}</h6>
            </div>
            <div className="row">
              <h5>Type de l&apos;évenement</h5>
              <h6>{data?.roomType}</h6>
            </div>
            <div className="row">
              <h5>Nombre de participants</h5>
              <h6>{data?.roomNbr}</h6>
            </div>

            <h3 className="title second">Besoins techniques</h3>
            <div className="row grid">
              <h5 className="label">élements</h5>
              <h5 className="label">Qté</h5>
            </div>
            {data?.needs?.map((item, index) => (
              <div className="row grid" key={index}>
                <h6>{item.name}</h6>
                <h6>{item.number}</h6>
              </div>
            ))}
          </div>

          <div className="col">
            <img src={data?.roomImg} alt="room" className="img" />
            <h3 className="title">Dates et horaires</h3>
            <div className="row">
              <h5 className="label">Date</h5>
              <h5 className="label">Description</h5>
            </div>
            {data?.dates?.map((item, index) => (
              <div className="row" key={index}>
                <h6 className="date-h6">
                  de {getDate(item.from)} à {getDate(item.to)}
                </h6>
                <h6 className="date-h6">{item.comment}</h6>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default ReservationsDetails;

const Container = styled.div`
  background: #fff;
  border-radius: 20px;
  width: 100%;
  padding: 1.5em;

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5em;
    margin: auto;
    margin-top: 2em;
    min-height: calc(100vh - 120px - 6.5em) !important;
  }
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 0.25fr;
  }
  .title {
    color: #202862;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 1.25em;
    margin-top: 1em;
    height: 30px;
    border-bottom: 4px solid #009685;
    width: fit-content !important;
  }
  .second {
    margin-top: 4em;
  }
  .img {
    width: 100%;
    border-radius: 20px;
    margin-top: 0.5em;
    max-width: 450px;
    max-height: 250px;
    object-fit: cover;
  }
  h6 {
    color: #202862;
    font-size: 15px;
    font-weight: 500;
    margin: 0.5em 0;
    text-transform: capitalize;
  }
  h5 {
    color: #009685;
    font-size: 15px;
    font-weight: 600;
    margin: 0.5em 0;
    text-transform: capitalize;
  }
  .date-row {
    display: flex;
    align-items: center;
  }
  .date-row h6 {
    margin-top: 2.15em;
    margin-left: 1em;
  }
  .date {
    color: red;
    font-weight: 600;
  }
  .label {
    padding-bottom: 0.5em;
    border-bottom: 1px solid #f1f1f1;
  }
  .date-h6 {
    font-size: 13px;
  }
  @media only screen and (max-width: 1280px) {
    .content {
      grid-template-columns: 1fr;
    }
    img {
      display: none;
    }
  }
  @media only screen and (max-width: 900px) {
    padding: 0;
  }
  @media only screen and (max-width: 576px) {
    .title {
      font-size: 16px;
    }
  }
`;
