import React, { createContext, useState, useEffect } from "react";
import jwt from "jsonwebtoken";

const AdminContext = createContext({});

export const AdminProvider = ({ children }) => {
  const [admin, setAdmin] = useState(null);

  const adminContext = {
    admin,
    setAdmin,
  };

  useEffect(() => {
    const storage = localStorage.getItem("userAuth");
    jwt.verify(
      storage,
      "d6d82b79-5226-454c-a36d-17bc13bcd6f2",
      (err, decoded) => {
        if (decoded) {
          setAdmin(decoded);
        }
      }
    );
  }, []);

  return (
    <AdminContext.Provider value={adminContext}>
      {children}
    </AdminContext.Provider>
  );
};

export const AdminConsumer = AdminContext.Consumer;

export default AdminContext;
