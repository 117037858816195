import React, { useState, useContext } from "react";
import styled from "styled-components";
import firebase from "firebase/app";
import storage from "firebase/storage";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import "firebase/auth";
import jwt from "jsonwebtoken";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import logo from "../assets/logo.png";
import Button from "../components/elements/Button";
import CustomInput from "../components/elements/CustomInput";
import PageTitle from "../components/elements/PageTitle";
import DatePicker from "../components/elements/DatePicker";
import loginimg from "../assets/signup.png";
import AdminContext from "../contexts/AdminContext";

const Signup = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const { setAdmin } = useContext(AdminContext);
  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const signup = async (data) => {
    try {
      const { user } = await firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.pwd);

      const obj = {
        id: user.uid,
        email: data.email,
        fname: data.fname,
        lname: data.lname,
        phone: data.phone,
        num_ice: data.num_ice,
        birthDate: firebase.firestore.Timestamp.fromDate(
          new Date(data.birthDate)
        ),
        ficheUrl: imgUrl,
        confirmed: false,
        type_compte: data.type_compte,
        profile:
          "https://firebasestorage.googleapis.com/v0/b/technopark-b1cdf.appspot.com/o/profiles%2Favatar.png?alt=media&token=16f3409b-8159-4fd1-b745-6ab5f3d4840b",
      };

      if (user) {
        await firebase.firestore().collection("users").doc(user.uid).set(obj);

        obj.birthDate = data.birthDate;
        const token = jwt.sign(obj, "d6d82b79-5226-454c-a36d-17bc13bcd6f2");
        localStorage.setItem("userAuth", token);
        setAdmin(obj);
        history.push("/");
      }
    } catch (err) {
      console.log(err);
      setError("L'adresse e-mail est déjà utilisée par un autre compte.");
    }
  };

  const uploadImage = async (file) => {
    setIsUploading(true);
    const storageRef = firebase
      .storage()
      .ref(`profiles/${new Date().getTime().toString()}`);
    await storageRef.put(file);
    const url = await storageRef.getDownloadURL();
    setImgUrl(url);
    setIsUploading(false);
  };

  return (
    <Container>
      <div className="wrp">
        <div className="left-part">
          <img src={loginimg} alt="img" />
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            email: "",
            fname: "",
            lname: "",
            phone: "",
            num_ice: "",
            file: "",
            type_compte: "entreprise",
            birthDate: new Date()
              .toLocaleDateString("en-US")
              .split("T")[0]
              .replaceAll("-", "/"),
            pwd: "",
            fiche: "",
            type_client: "externe",
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Email invalid")
              .required("Champs Obligatoire"),
            pwd: Yup.string()
              .min(6, "Doit comporter au moins 6 caractères")
              .required("Champs Obligatoire"),
            fname: Yup.string().required("Champs Obligatoire"),
            lname: Yup.string().required("Champs Obligatoire"),
            phone: Yup.string().required("Champs Obligatoire"),
            num_ice: Yup.string().when("type_compte", {
              is: (type_compte) => type_compte === "entreprise",
              then: Yup.string().required("Champs Obligatoire"),
            }),
            type_compte: Yup.string().required("Champs Obligatoire"),
          })}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            signup(data);
            setSubmitting(false);
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            values,
            isSubmitting,
            errors,
            touched,
          }) => {
            console.log(errors);
            return (
              <div className="right-part">
                <PageTitle title="Créer votre compte Technopark" />

                <Form className="form">
                  <img src={logo} alt="logo" className="logo" />

                  <div className="sub-form">
                    <div className="form-row">
                      <CustomInput
                        margin="0 0.5em"
                        label="Prénom"
                        name="fname"
                        id="fname"
                        type="text"
                        disabled
                      />
                      <CustomInput
                        margin="0 0.5em"
                        label="Nom"
                        name="lname"
                        id="lname"
                        type="text"
                        disabled
                      />
                    </div>
                    <div className="form-row">
                      <CustomInput
                        margin="0 0.5em"
                        label="Email"
                        name="email"
                        id="email"
                        type="text"
                      />
                      <CustomInput
                        margin="0 0.5em"
                        label="Téléphone"
                        name="phone"
                        id="phone"
                        type="text"
                      />
                    </div>
                    <div className="form-row">
                      <DatePicker
                        date={values.birthDate}
                        name="birthDate"
                        label="Date de naissance"
                        setFieldValue={setFieldValue}
                      />
                      <CustomInput
                        margin="0 0.5em"
                        label="Mot de passe"
                        name="pwd"
                        id="pwd"
                        type="password"
                        placeholder="Mot de passe"
                      />
                    </div>
                    <Field as="select" name="type_compte" id="type_compte">
                      <option value="organisation">Association</option>
                      <option value="entreprise">Entreprise</option>
                    </Field>
                    {error ? <p className="error">{error}</p> : null}
                    {values.type_compte === "entreprise" && (
                      <CustomInput
                        margin="0 0.5em"
                        label="N° ICE"
                        name="num_ice"
                        id="num_ice"
                        type="text"
                      />
                    )}
                    {values.type_compte === "organisation" && (
                      <>
                        <input
                          type="file"
                          name="fiche"
                          className="custom-file-input"
                          accept="image/*"
                          onChange={(e) => uploadImage(e.target.files[0])}
                        />
                        {errors.file && touched.file && (
                          <p className="red">{errors.file}</p>
                        )}
                        {isUploading && (
                          <p style={{ color: "red" }} className="wait">
                            veuillez patienter ...
                          </p>
                        )}
                      </>
                    )}
                    <div className="btn-wrp">
                      <Button
                        disabled={isUploading}
                        handleClick={handleSubmit}
                        title={isSubmitting ? "S'inscrire..." : "S'inscrire"}
                        type="submit"
                        radius="7px"
                        color="#202862"
                      />
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
};

export default Signup;

const Container = styled.div`
  .wait {
    color: red;
    padding: 10px 0;
  }
  select {
    padding: 10px;
    font-size: 14px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  background-color: #e5e5e5;
  min-height: 100vh;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrp {
    background-color: #fff;
    width: 100%;
    max-width: 1100px;
    height: 100%;
    max-height: 750px;
    display: grid;
    grid-template-columns: 50% 50%;
    border-radius: 20px;
    overflow: hidden;
  }
  .left-part {
    width: 100%;
    img {
      max-height: 750px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .right-part {
    display: grid;
    grid-template-rows: 80px auto;
    padding: 2em;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .form-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    img {
      width: 100%;
      max-width: 140px;
    }
  }
  .bottom-btns-wrp {
    display: flex;
    flex-direction: column;
    .link-type-btn {
      font-size: 0.8rem;
      padding: 0.5em;
      margin: 0.5em 0;
      font-weight: 600;
      color: #0b3be8;
      text-decoration: underline;
      background: transparent;
    }
  }
  .error {
    font-size: 12px;
    color: red;
    font-weight: 600;
    text-align: left;
    width: 100%;
  }
  .form-row {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-column-gap: 1em;
  }
  .sub-form {
    padding: 1em 0;
    width: 100%;
  }
  .left-side {
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25vw;
    min-width: 350px;
    max-width: 420px;
    margin-bottom: 1em;
    height: fit-content;
    margin-right: 1.5em;
  }
  .right-side {
    max-width: 750px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 1em;
  }
  input,
  textarea,
  .MuiInputBase-root {
    width: 100% !important;
    min-width: 170px !important;
  }
  .btn-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
  }
  .logo {
    width: 100%;
    max-width: 140px;
  }

  @media only screen and (max-width: 960px) {
    .wrp {
      grid-template-columns: 100% !important;
      max-height: unset;
    }
    .left-part {
      display: none;
    }
    .right-part {
      padding: 1em;
    }
  }

  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
    margin-top: 20px;
  }
  .custom-file-input::before {
    content: "Ajouter une fiche";
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
`;
