import React from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import logo from "../assets/logo.png";
import Button from "./elements/Button";
import CustomInput from "./elements/CustomInput";
import PageTitle from "./elements/PageTitle";
import loginimg from "../assets/login.png";

const Login = ({ submitData, setState, state, error }) => {
  const history = useHistory();

  return (
    <Container>
      <div className="left-part">
        <img src={loginimg} alt="img" />
      </div>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={Yup.object({
          email: Yup.string().email("Email invalide").required("Obligatoire"),
          password: Yup.string().required("Obligatoire"),
        })}
        onSubmit={async (data) => {
          setState(true);
          await submitData(data.email, data.password);
          setState(false);
        }}
      >
        {() => (
          <div className="right-part">
            <PageTitle title="Authentification" />
            <Form className="form">
              <div className="form-content">
                <img src={logo} alt="logo" />
                <CustomInput
                  label="Email"
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Email"
                />
                <CustomInput
                  label="Mot de passe"
                  name="password"
                  id="password"
                  type="password"
                  placeholder="Mot de passe"
                />
                <p className="error">{error}</p>
                <div className="submit-btn">
                  <Button
                    title={state ? "Se Connecter..." : "Se Connecter"}
                    width="large"
                    color="#009685"
                    type="submit"
                    radius="4px"
                  />
                </div>
              </div>
              <div className="bottom-btns-wrp">
                <button
                  onClick={() => history.push("/signup")}
                  className="link-type-btn"
                  type="button"
                >
                  Créer un compte
                </button>
                {/* <button
                  onClick={() => history.push("/verification")}
                  className="link-type-btn"
                  type="button"
                >
                  Mot de passe oublié ?
                </button> */}
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </Container>
  );
};

export default Login;

const Container = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 1100px;
  height: 100%;
  max-height: 700px;
  display: grid;
  grid-template-columns: 50% 50%;
  border-radius: 20px;
  overflow: hidden;
  .left-part {
    width: 100%;
    img {
      max-height: 700px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .right-part {
    display: grid;
    grid-template-rows: 80px auto;
    padding: 2em;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .form-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    img {
      width: 100%;
      max-width: 140px;
    }
  }
  .bottom-btns-wrp {
    display: flex;
    flex-direction: column;
    .link-type-btn {
      font-size: 0.8rem;
      padding: 0.5em;
      font-weight: 600;
      margin: 0.5em 0;
      color: #0b3be8;
      text-decoration: underline;
      background: transparent;
    }
  }
  input {
    width: 100%;
  }
  .error {
    font-size: 12px;
    color: red;
    font-weight: 600;
    text-align: left;
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: 100% !important;
    .left-part {
      display: none;
    }
    .right-part {
      padding: 1em;
    }
  }
`;
