import React, { Fragment, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import PriceContext from "../contexts/PriceContext";
import ReservationContext from "../contexts/ReservationContext";

const Fifth = ({ data }) => {
  const [total, setTotal] = useState(0);
  const { reservation } = useContext(ReservationContext);

  const { price } = useContext(PriceContext);
  console.log("well : ", reservation);

  useEffect(() => {
    let t = 0;
    data.map((item) => {
      t += item.total;
    });
    setTotal(t);
  }, []);
  return (
    <Container>
      <h3>Votre devis:</h3>
      <div className="table">
        <div className="grid">
          <h6>Désignation</h6>
          <h6>Prix Unitair</h6>
          <h6>Quantité</h6>
          <h6>Total</h6>
          <h5>Salle {reservation.roomName}</h5>
          <h5>{price} DH</h5>
          <h5>1</h5>
          <h5>{price} DH</h5>
        </div>
      </div>

      <div className="price-row">
        <div className="price-row-wrp">
          <p className="blue">Prix Hors Taxe </p>
          <p className="total">{price} dhs </p>
        </div>
        <div className="price-row-wrp">
          <p className="blue">TVA</p>
          <p className="total">20% </p>
          <p>Weekend</p>
        </div>
        <div className="price-row-wrp">
          <p className="blue">Total Net à payer TTC </p>
          <p className="total bold">{price} dhs </p>
        </div>
      </div>
    </Container>
  );
};

export default Fifth;

const Container = styled.div`
  background: #fff;
  border-radius: 20px;
  width: 100%;
  padding: 2em 2em 3em 2em;
  margin: auto;
  h3 {
    color: #202862;
    font-weight: 600 !important;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .page-container {
    padding: 0 1em 1em 1em;
    border-radius: 10px;
    background: #fff;
    box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
      rgb(237 239 247 / 47%) 0px 0px 0px;
  }
  .form {
    width: 100%;
    margin-top: 2em;
    padding: 2em 0;
    border-radius: 20px;
    background: #fff;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    margin-bottom: 2em;
  }
  h6 {
    color: rgb(128, 128, 128);
    font-weight: 600;
    font-size: 13px;
    text-transform: capitalize;
    color: #009685;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  h5 {
    color: rgb(128, 128, 128);
    font-size: 13px;
    font-weight: 600;
    color: #202862;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
    text-transform: capitalize;
  }
  .table {
    overflow-x: auto;
  }
  .grid {
    display: grid;
    grid-template-columns: 2.2fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    border-bottom: 1px solid #dfe0eb;
    width: 100%;
  }
  input {
    min-width: 70px;
    margin: 1em 0.5em 1em 0;
    width: calc(100% - 0.5em);
    border-radius: 5px !important;
  }
  label {
    display: none;
  }
  button {
    margin: 1em 0;
    width: 100% !important;
  }
  .delete {
    justify-content: center;
    padding: 0;
    svg {
      cursor: pointer;
    }
  }
  p {
    font-size: 11px;
    color: red;
    font-weight: 600;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    button {
      margin-top: 0;
      margin-bottom: 2.2em;
    }
  }
  .confirm {
    background: transparent !important;
    border-radius: 7px;
    border: 1.5px solid #187bcd;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .edit-form {
    input {
      border: 1.5px solid #187bcd;
    }
  }
  .price-row {
    display: flex;
    width: 100%;
    align-items: flex-end;
    margin-top: 1.5em;
    margin-bottom: 4em;
    flex-direction: column;
  }
  .price-row-wrp {
    display: grid;
    grid-template-columns: 200px 100px;
  }
  .blue {
    font-size: 15px;
    font-weight: 600;
    color: #009685;
    margin-right: 2em;
    margin-top: 1em;
  }
  .total {
    font-size: 15px;
    font-weight: 500;
    color: #202862;
    margin-top: 1em;
  }
  .bold {
    font-weight: 700;
  }

  @media only screen and (max-width: 900px) {
    padding: 0;
  }
  @media only screen and (max-width: 576px) {
    .price-row {
      align-items: flex-start;
    }
    .price-row-wrp {
      display: grid;
      grid-template-columns: 180px 100px;
    }
    h6,
    h5 {
      font-size: 10px;
    }
  }
`;
