import React from "react";
import styled from "styled-components";
import { ReactComponent as Step1Svg } from "../assets/salle.svg";
import { ReactComponent as Step2Svg } from "../assets/step2.svg";
import { ReactComponent as Step3Svg } from "../assets/calendar.svg";
import { ReactComponent as Step4Svg } from "../assets/step4.svg";
import { ReactComponent as Step5Svg } from "../assets/step5.svg";
import { ReactComponent as Step6Svg } from "../assets/step6.svg";
import { ReactComponent as City } from "../assets/icons8-city-64.svg";

const Steps = ({ step, room }) => {
  console.log("Room in steps : ", room, step);
  return (
    <Container room={room}>
      <div className={step === 0 ? "nbr active-step" : "nbr"}>
        <City />
      </div>
      <hr />
      <div className={step === 1 ? "nbr active-step" : "nbr"}>
        <Step1Svg />
      </div>
      <hr />
      <div className={step === 2 ? "nbr active-step" : "nbr"}>
        <Step2Svg />
      </div>
      <hr />
      <div className={step === 3 ? "nbr active-step" : "nbr"}>
        <Step4Svg />
      </div>

      {(room?.type == "formation" || room?.type == "Réunion") && (
        <>
          <hr />
          <div className={step === 5 ? "nbr active-step" : "nbr"}>
            <Step5Svg />
          </div>
          <hr />
          <div className={step === 6 ? "nbr active-step" : "nbr"}>
            <Step6Svg />
          </div>
        </>
      )}
    </Container>
  );
};

export default Steps;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin: auto;
  margin: 2em auto;
  .nbr {
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    font-weight: 600;
    color: #202862;
    border: 2px solid #202862;
    border-radius: 100px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
  hr {
    width: ${(props) =>
      props.room?.type === "formation" || props.room?.type === "Réunion"
        ? `calc((100% - 300px) / 5)`
        : "calc((100% - 300px) / 3)"};
    background: #202862;
    height: 2px;
    border: none;
  }

  .active-step {
    background: #202862;
    color: #fff;
    svg {
      fill: #fff;
      path {
        fill: #fff;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    width: 90%;
    .nbr {
      width: 50px;
      height: 50px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    hr {
      width: ${(props) =>
        props.room?.type === "formation"
          ? `calc((100% - 300px) / 5)`
          : "calc((100% - 300px) / 3)"};
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  @media only screen and (max-width: 576px) {
    .body {
      padding: 3em 0em;
    }
    .nbr {
      width: 35px;
      height: 35px;
      font-size: 18px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    hr {
      width: calc((100% - 210px) / 5);
    }
  }
`;
