import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import PageTitle from "../components/elements/PageTitle";
import Layout from "../layouts/DefaultLayout";
import usePageTitle from "../hooks/useTitlefromUrl";
import CityStep from "../components/CityStep";
import FirstStep from "../components/FirstStep";
import ThirdStep from "../components/ThirdStep";
import FourthStep from "../components/FourthStep";
import FifthStep from "../components/FifthStep";
import SixthStep from "../components/SixthStep";
import Steps from "../components/Steps";
import NavigationButtons from "../components/NavigationButtons";
import AdminContext from "../contexts/AdminContext";
import StepContext from "../contexts/StepContext";
import SecondStep from "../components/secondStep/index";

const Dashboard = () => {
  const location = useLocation();

  const [rooms, setRooms] = useState([]);
  const [fetch, setFetch] = useState(0);
  const [room, setRoom] = useState(null);
  const [city, setCity] = useState(null);
  const [needs, setNeeds] = useState([]);
  const [type, setType] = useState("Conférence");
  const [thematique, setThematique] = useState("");
  const [tools, setTools] = useState([]);
  const [status, setStatus] = useState("Awaiting");
  const [prices, setPrices] = useState([]);
  const [error, setError] = useState(0);
  const [date, setDate] = useState({ day: "", time: "", comment: "" });
  let mounted = true;
  const { step, setStep } = useContext(StepContext);
  const pagetile = usePageTitle(step);
  const { admin } = useContext(AdminContext);
  const [customType, setCustomType] = useState("");

  const db = firebase.firestore();

  console.log(admin);

  const getRooms = async () => {
    setFetch(1);
    const tempArray = [];
    try {
      await db
        .collection("rooms")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            const room = doc.data();
            room.id = doc.id;
            tempArray.push(room);
          });
        });
      setFetch(0);
      setRooms(tempArray);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getTools = async () => {
    const tempArray = [];
    try {
      await db
        .collection("tools")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            const tool = doc.data();
            tempArray.push(tool.name);
          });
        });
      setTools(tempArray);
    } catch (err) {
      console.log(err.message);
    }
  };

  const periodeTime = {
    1: {
      id: 1,
      name: "8h à 12h",
    },
    2: {
      id: 2,
      name: "14h à 18h",
    },
    3: {
      id: 3,
      name: "Soir",
    },
    4: {
      id: 4,
      name: "Toute la journée",
    },
  };

  const send = async (data) => {
    await db
      .collection("reservations")
      .add({
        createdAt: new Date(),
        ...data,
        ...(data?.reservation_type === "formation" && {
          time: periodeTime[data?.time]?.name,
        }),
        userId: admin.id,
      })
      .then(() => {
        setStep(step + 1);
        setStatus("done");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (mounted) {
      getRooms();
      getTools();
    }
    return () => {
      mounted = false;
    };
  }, []);

  useLayoutEffect(() => {
    if (location?.state) {
      setStatus(location?.state.status);
      setPrices(location?.state.prices);
      setStep(4);
    }
  }, [location]);

  const renderContent = () => {
    switch (step) {
      case 0:
        return (
          <CityStep
            data={rooms}
            status={fetch}
            setCity={setCity}
            setStep={setStep}
          />
        );
      case 1:
        return (
          <FirstStep
            data={rooms}
            status={fetch}
            setRoom={setRoom}
            setStep={setStep}
            city={city}
            setCity={setCity}
          />
        );
      case 2:
        return (
          <SecondStep
            customType={customType}
            setCustomType={setCustomType}
            room={room}
            setNeeds={setNeeds}
            needs={needs}
            type={type}
            setType={setType}
            thematique={thematique}
            setThematique={setThematique}
            tools={tools}
            setDate={setDate}
            date={date}
            error={error}
            setError={setError}
          />
        );
      case 3:
        return <FourthStep status={status} />;

      case 4:
        return <FifthStep data={prices} />;
      case 5:
        return <SixthStep data={prices} />;
      default:
    }
  };

  return (
    <Layout>
      <Container>
        <PageTitle title={pagetile} />
        <Steps step={step} room={room} />
        {renderContent()}
        <NavigationButtons
          setStep={setStep}
          step={step}
          status={status}
          send={send}
          setError={setError}
        />
      </Container>
    </Layout>
  );
};

export default Dashboard;

const Container = styled.div`
  background: #fff;
  border-radius: 20px;
  width: 100%;
  padding: 1.5em;
  display: grid;
  grid-template-rows: 40px calc(70px + 4em) auto auto;
  @media only screen and (max-width: 567px) {
    padding: 1.5em 0.5em;
  }
`;
