import React, { useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";
import AdminContext from "../contexts/AdminContext";
import Button from "./elements/Button";

const Header = ({ setMenuIsActive, menuIsActive, path }) => {
  const history = useHistory();
  const { admin } = useContext(AdminContext);

  const signOut = () => {
    localStorage.removeItem("userAuth");
    history.push("/login");
  };

  return (
    <Container>
      {menuIsActive ? (
        <svg
          fill="#202862"
          xmlns="http://www.w3.org/2000/svg"
          height="32"
          viewBox="0 0 24 24"
          width="32"
          className="menu-icon"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        </svg>
      ) : (
        <svg
          width="28"
          height="28"
          viewBox="0 0 36 21"
          fill="#202862"
          className="menu-icon"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <rect y="18" width="36" height="3" rx="1.5" fill="#202862" />
          <rect y="9" width="36" height="3" rx="1.5" fill="#202862" />
          <rect width="36" height="3" rx="1.5" fill="#202862" />
        </svg>
      )}
      <div className="header-right">
        <span>
          {admin.fname} {admin.lname}
        </span>
        <Link to="profile">
          <img src={admin.profile} alt="gg" className="user-photo" />
        </Link>
      </div>
      {path === "/profile" ? (
        <div className="header-right-side">
          <Button
            handleClick={signOut}
            radius="7px"
            margin="0"
            font="14px"
            padding="5px 7px"
            color="#202862"
          />
        </div>
      ) : null}
    </Container>
  );
};

export default Header;

const Container = styled.div`
  width: 100%;
  height: 70px;
  padding: 0 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1020;
  background: #dedede;
  .header-right {
    display: flex;
    justify-content: center;
    align-items: center;
    .user-photo {
      width: 42px;
      height: 42px;
      cursor: pointer;
      border-radius: 100px;
      object-fit: cover;
      margin-right: 1em;
    }
    span {
      margin: 0 1em;
      color: #202862;
      font-weight: 600;
      font-size: 13px;
    }
  }
  svg {
    cursor: pointer;
  }

  @media only screen and (min-width: 768px) {
    justify-content: flex-end;
    .menu-icon {
      display: none;
    }
  }
`;
