import React, { useState, useEffect } from "react";
import styled from "styled-components";
import pyp from "../assets/pyp.png";
import cmi from "../assets/cmi.png";
import CustomInput from "./elements/CustomInput";

const SixthStep = ({ data }) => {
  const [total, setTotal] = useState(0);
  const [type, setType] = useState("card");

  useEffect(() => {
    let t = 0;
    data.map((item) => {
      t += item.total;
    });
    setTotal(t);
  }, []);

  return (
    <Container>
      <div className="content">
        <div className="col">
          <h3 className="title">Mode de paiement :</h3>
          <div className="wrp">
            <div className="radio">
              <input
                type="radio"
                id="pyp"
                checked={type === "pyp"}
                onChange={(e) => setType("pyp")}
              />
              <label htmlFor="pyp">
                <img src={pyp} alt="pyp" />
              </label>
            </div>
            <div className="radio">
              <input
                type="radio"
                id="card"
                checked={type === "card"}
                onChange={(e) => setType("card")}
              />
              <label htmlFor="card">
                <span>Carte de crédit ou de débit </span>
                <img src={cmi} alt="pyp" />
              </label>
            </div>

            <div className="card">
              <div className="input-text">
                <label htmlFor="card">Numéro de carte</label>
                <input placeholder="0000  0000  0000  0000" type="text" />
              </div>
              <div className="inputs-row">
                <div className="input-text">
                  <label htmlFor="card">Date d&apos;expiration</label>
                  <input placeholder="MM/AA" type="text" />
                </div>
                <div className="input-text">
                  <label htmlFor="card">CVC/CVV</label>
                  <input placeholder="123" type="text" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <h3 className="title">Résumé :</h3>
          <div className="wrp">
            <div className="row">
              <h5>Prix Hors Taxe</h5>
              <h6>{total} dhs</h6>
            </div>
            <div className="row">
              <h5>TVA</h5>
              <h6>20%</h6>
            </div>
            <div className="row blue">
              <h5>Total Net à payer TTC</h5>
              <h6>{total + total * 0.2} dhs</h6>
            </div>
            <button>Payez maintenant </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SixthStep;

const Container = styled.div`
  background: #fff;
  border-radius: 20px;
  width: 100%;
  padding: 1.5em;

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5em;
    margin: auto;
    margin-top: 2em;
    min-height: calc(100vh - 180px - 17em) !important;
  }
  .title {
    color: #202862;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 1.25em;
    margin-top: 1em;
    height: 30px;
    border-bottom: 4px solid #009685;
    width: fit-content !important;
  }
  .row {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    align-items: center;
  }
  button {
    width: calc(100% - 3em);
    padding: 9px 26px;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    background-color: #009685;
    border-radius: 7px;
    margin: 5.8em 1.5em 1em 1.5em;
  }
  h5 {
    font-size: 14px;
    font-weight: 500;
    color: #009685;
    padding: 1em 1.5em;
    border-bottom: 1px solid #eaeaea;
  }
  h6 {
    font-size: 14px;
    font-weight: 500;
    color: #202862;
    padding: 1em 1.5em 1em 0;
    border-bottom: 1px solid #eaeaea;
  }
  .wrp {
    border: 1px solid #eaeaea;
    border-radius: 7px;
  }
  .blue {
    background-color: #202862;
    h5,
    h6 {
      color: #fff;
    }
  }
  .radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    border-bottom: 1px solid #eaeaea;
  }
  span {
    margin-right: 1em;
  }
  .radio:nth-child(2) {
    border-bottom: 0px;
  }

  input[type="radio"] {
    margin-right: 1em;
  }
  label {
    color: #202862;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .input-text {
    display: flex;
    flex-direction: column;
    padding: 0.5em 1em;
  }
  input[type="text"] {
    padding: 10px;
    font-size: 14px;
    border-radius: 7px;
    border: 1px solid #838fe8;
    color: #838fe8;
    margin-top: 0.5em;
    background-color: #fbfbfb;
  }
  .inputs-row {
    width: 100%;
    display: grid;
    grid-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  img {
    max-height: 30px;
  }
  @media only screen and (max-width: 1280px) {
    .content {
      grid-template-columns: 1fr;
    }
  }
  @media only screen and (max-width: 567px) {
    padding: 1.5em 0;
  }
`;
