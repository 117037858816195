import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import firebase from "firebase/app";
import "firebase/auth";
import jwt from "jsonwebtoken";
import { useHistory } from "react-router-dom";
import AdminContext from "../contexts/AdminContext";
import LoginFormComponent from "../components/LoginForm";

const Login = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(0);
  const history = useHistory();
  const { setAdmin } = useContext(AdminContext);

  useEffect(() => {
    const admin = localStorage.getItem("userAuth");

    jwt.verify(
      admin,
      "d6d82b79-5226-454c-a36d-17bc13bcd6f2",
      async (err, decoded) => {
        if (decoded) {
          history.push("/");
        }
      }
    );
  }, []);

  const login = async (email, pwd) => {
    setError(null);

    if (email && pwd) {
      try {
        const res = await firebase
          .auth()
          .signInWithEmailAndPassword(email, pwd);
        if (res.user.uid) {
          const db = firebase.firestore();

          await db
            .collection("users")
            .doc(res.user.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const user = doc.data();
                user.id = doc.id;
                user.birthDate = user.birthDate.toDate().getTime();

                const token = jwt.sign(
                  user,
                  "d6d82b79-5226-454c-a36d-17bc13bcd6f2"
                );
                localStorage.setItem("userAuth", token);
                setAdmin(user);
                history.push("/");
              } else {
                setError("Email ou mot de passe invalide");
              }
            })
            .catch((e) => {
              console.log(e);
              setError(e.message);
            });
        }
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
    }
  };

  return (
    <Container>
      <LoginFormComponent
        submitData={login}
        setState={setLoading}
        state={loading}
        error={error}
      />
    </Container>
  );
};

export default Login;

const Container = styled.div`
  background-color: #e5e5e5;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
