import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const ReservationCart = ({ data }) => {
  const history = useHistory();
  console.log(data);

  return (
    <Container
      onClick={() => {
        if (
          data.status === "Awaiting" ||
          (data.status === "Confirmed" && data.paid === false)
        ) {
          history.push({
            pathname: "/",
            state: data,
          });
        } else {
          history.push({
            pathname: "/reservations-details",
            state: data,
          });
        }
      }}
    >
      <div className="row">
        <img src={data.roomImg} alt="room" />
        <aside>
          <h2>Salle {data.roomName}</h2>
          <h6>{data.date}</h6>
          <button
            className={
              data.status === "Confirmed"
                ? "btn"
                : data.status === "Awaiting"
                ? "btn yellow"
                : "btn red"
            }
            onClick={() => {
              if (
                data.status === "Awaiting" ||
                (data.status === "Confirmed" && data.paid === false)
              ) {
                history.push({
                  pathname: "/",
                  state: data,
                });
              } else {
                history.push({
                  pathname: "/reservations-details",
                  state: data,
                });
              }
            }}
          >
            {data.status === "Confirmed"
              ? "Confirmé"
              : data.status === "Awaiting"
              ? "En traitement"
              : "Annulé"}
          </button>
        </aside>
      </div>
      <button className="see-more">Voir plus</button>
    </Container>
  );
};

export default ReservationCart;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
  padding: 1em;
  transition: all 0.3s;
  margin: 1em 0;
  cursor: pointer;
  &:hover {
    background-color: #202862;
    border-radius: 20px;
    .see-more {
      display: block;
    }
    h2,
    h6 {
      color: #fff;
    }
  }
  .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  img {
    width: 100%;
    max-width: 300px;
    margin-right: 1.5em;
    border-radius: 20px;
  }
  h2 {
    font-size: 22px;
    color: #202862;
    font-weight: 700;
    margin-bottom: 0.25em;
    margin-top: 0.25em;
  }
  h6 {
    font-size: 15px;
    color: #c30a1d;
    font-weight: 600;
    margin-bottom: 1em;
  }
  .btn {
    padding: 7px 12px;
    border-radius: 7px;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    background-color: #28a745;
    cursor: default;
  }
  .yellow {
    background-color: #ffc107;
  }
  .red {
    background-color: #c30a1d;
  }
  .see-more {
    width: 90px;
    background: #fff;
    border-radius: 7px;
    color: #202862;
    font-size: 13px;
    font-weight: 600;
    display: none;
    padding: 7px 2px;
  }
  @media only screen and (max-width: 980px) {
    .see-more {
      display: none !important;
    }
  }
`;
