import React, { useState, useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import jwt from "jsonwebtoken";
import "firebase/firestore";
import styled from "styled-components";
import Button from "../components/elements/Button";
import Layout from "../layouts/DefaultLayout";
import CustomInput from "../components/elements/CustomInput";
import DatePicker from "../components/elements/DatePicker";
import AdminContext from "../contexts/AdminContext";

const EditUser = () => {
  const [loading, setLoading] = useState(0);
  const [error, setError] = useState(0);
  const [uploading, setUploading] = useState(0);
  const { admin, setAdmin } = useContext(AdminContext);
  const [image, setImage] = useState(null);
  const db = firebase.firestore();
  const history = useHistory();

  const uploadImage = async (file) => {
    setUploading(1);
    const storageRef = firebase
      .storage()
      .ref(`profiles/${new Date().getTime().toString()}`);
    await storageRef.put(file);
    const url = await storageRef.getDownloadURL();
    setImage(url);
    setUploading(0);
  };

  const update = async (data) => {
    setError(0);

    const credentials = firebase.auth.EmailAuthProvider.credential(
      admin.email,
      data.pwd
    );
    await firebase
      .auth()
      .currentUser.reauthenticateWithCredential(credentials)
      .then(async () => {
        await db
          .collection("users")
          .doc(admin.id)
          .update({
            email: data.email,
            fname: data.fname,
            lname: data.lname,
            phone: data.phone,
            birthDate: firebase.firestore.Timestamp.fromDate(
              new Date(data.birthDate)
            ),
            profile: image || admin.profile,
          });

        const currentUser = firebase.auth().currentUser;
        if (admin.email !== data.email) {
          await currentUser.updateEmail(data.email);
        }
        const obj = {
          id: admin.id,
          ...data,
          profile: image || admin.profile,
        };
        setAdmin(obj);
        const token = jwt.sign(obj, "d6d82b79-5226-454c-a36d-17bc13bcd6f2");
        localStorage.setItem("userAuth", token);
        setLoading(false);
        alert("Vos informations ont été mises à jour avec succès");
        history.push("/");
      })
      .catch((err) => {
        console.log(err);
        setError("Votre mot de passe est incorrect");
        setLoading(false);
      });
  };

  return (
    <Layout>
      <Container>
        <Formik
          enableReinitialize
          initialValues={{
            email: admin?.email || "",
            fname: admin?.fname || "",
            lname: admin?.lname || "",
            phone: admin?.phone || "",
            birthDate:
              admin?.birthDate ||
              new Date()
                .toLocaleDateString("en-US")
                .split("T")[0]
                .replaceAll("-", "/"),
            pwd: "",
            type_compte: `Client ${admin?.type_compte}`,
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Email invalid")
              .required("Champs Obligatoire"),
            pwd: Yup.string().min(6, "Doit comporter plus de 6 caractères"),
            fname: Yup.string().required("Champs Obligatoire"),
            lname: Yup.string().required("Champs Obligatoire"),
            phone: Yup.string().required("Champs Obligatoire"),
          })}
          onSubmit={async (data) => {
            setLoading(true);
            update(data);
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => (
            <Form className="form">
              <h3>Profile</h3>
              <label htmlFor="file-input" className="file-label">
                {uploading ? (
                  <div className="loader" title="2">
                    <svg
                      version="1.1"
                      id="loader-1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="40px"
                      height="40px"
                      viewBox="0 0 50 50"
                      enableBackground="new 0 0 50 50;"
                    >
                      <path
                        fill="#000"
                        d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                      >
                        <animateTransform
                          attributeType="xml"
                          attributeName="transform"
                          type="rotate"
                          from="0 25 25"
                          to="360 25 25"
                          dur="0.6s"
                          repeatCount="indefinite"
                        />
                      </path>
                    </svg>
                  </div>
                ) : null}
                <img
                  src={image || admin?.profile}
                  alt="avatar"
                  className="avatar"
                />
                <span className="popover">Changez votre profile photo</span>
              </label>
              <div className="sub-form">
                <input
                  type="file"
                  name="file"
                  id="file-input"
                  onChange={(e) => uploadImage(e.target.files[0])}
                />
              </div>
              <div className="sub-form">
                <div className="form-row">
                  <CustomInput
                    margin="0 0.5em"
                    label="Prénom"
                    name="fname"
                    id="fname"
                    type="text"
                    disabled
                  />
                  <CustomInput
                    margin="0 0.5em"
                    label="Nom"
                    name="lname"
                    id="lname"
                    type="text"
                    disabled
                  />
                </div>
                <div className="form-row">
                  <CustomInput
                    margin="0 0.5em"
                    label="Email"
                    name="email"
                    id="email"
                    type="text"
                  />
                  <CustomInput
                    margin="0 0.5em"
                    label="Téléphone"
                    name="phone"
                    id="phone"
                    type="text"
                  />
                </div>
                <div className="form-row">
                  <DatePicker
                    date={values.birthDate}
                    name="birthDate"
                    label="Date de naissance"
                    setFieldValue={setFieldValue}
                  />
                  <CustomInput
                    margin="0 0.5em"
                    label="Mot de passe actuel"
                    name="pwd"
                    id="pwd"
                    type="password"
                    placeholder="Mot de passe"
                  />
                  <CustomInput
                    disabled={true}
                    margin="0 0.5em"
                    label="Type du Compte"
                    name="type_compte"
                    id="type_compte"
                    type="text"
                    placeholder="Type Compte"
                  />
                </div>
                {error ? <p className="error">{error}</p> : null}
                <div className="btn-wrp">
                  <Button
                    handleClick={handleSubmit}
                    title={loading ? "Modifier..." : "Modifier"}
                    type="submit"
                    radius="7px"
                    color="#202862"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  );
};

export default EditUser;

const Container = styled.div`
  background: #fff;
  width: 100%;
  max-width: 900px;
  margin: auto;
  margin-top: 2em;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  .form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-column-gap: 2em;
  }
  .sub-form {
    padding: 1em 2em;
    width: 100%;
  }
  .left-side {
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25vw;
    min-width: 350px;
    max-width: 420px;
    margin-bottom: 1em;
    height: fit-content;
    margin-right: 1.5em;
  }
  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 200px;
    object-fit: cover;
  }
  .right-side {
    max-width: 750px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 1em;
  }
  input,
  textarea {
    width: 100% !important;
  }
  h3 {
    width: 100%;
    color: #3b4f64 !important;
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 0.5em;
    border-bottom: 4px solid #009685;
    margin-bottom: 2em;
  }
  .profil {
    padding: 1.5em 2em 0.5em 2em;
  }
  form {
    width: 100%;
  }
  #file-input {
    display: none;
  }
  .file-label {
    margin-top: 1em;
  }
  .btn {
    position: relative;
    background: #fff;
    padding: 10px 12px;
    border-radius: 7px;
    font-size: 14px;
    display: flex;
    border: 1px solid #f84b60;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    margin-top: 0.75em;
    margin-bottom: 1.5em;
    color: #f84b60;
    cursor: pointer;
    svg {
      margin-left: 5px;
    }
  }
  p {
    padding: 0.75em 1em;
    text-align: justify;
    color: #5a6169;
  }
  label {
    position: relative;
  }
  .loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    width: 120px;
    height: 120px;
    svg path,
    svg rect {
      fill: #f84b60;
    }
  }
  .popover {
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    font-family: "Helvetica", sans-serif;
    padding: 7px 10px;
    z-index: 4;
    position: absolute;
    left: -65px;
    top: -5px;
    display: none;
    &:before {
      border-left: 7px solid rgba(0, 0, 0, 0.85);
      border-bottom: 7px solid transparent;
      border-top: 7px solid transparent;
      content: "";
      display: block;
      left: 100%;
      position: absolute;
    }
  }
  .active {
    display: block;
  }
  .hidden {
    width: 0;
    height: 0;
    position: absolute;
  }
  .error {
    color: red;
    font-weight: 600;
    font-size: 12px;
    padding: 0 !important;
  }
  .avatar:hover + span {
    display: block;
  }
  .popover {
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    font-family: "Helvetica", sans-serif;
    padding: 7px 10px;
    z-index: 4;
    position: absolute;
    left: -110px;
    top: 35px;
    display: none;
    width: 100px;
    line-height: 1.5em;
    text-align: justify;
    &:before {
      top: 18px;
      border-left: 7px solid rgba(0, 0, 0, 0.85);
      border-bottom: 7px solid transparent;
      border-top: 7px solid transparent;
      content: "";
      display: block;
      left: 100%;
      position: absolute;
    }
  }
  .btn-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .select {
    min-width: 260px;
    width: 100%;
    padding: 10px;
    max-width: 470px;
    margin: 0.25em 0;
  }
  .city-select-wrp {
    display: flex;
    flex-direction: column;
    label {
      font-size: 14px;
      font-weight: 600;
      color: #4d4d4d;
      margin-bottom: 0.35em;
      margin-top: 1.4em;
    }
  }
  select {
    font-size: 14px;
    border-radius: 7px;
    border: 2px solid rgba(0, 0, 0, 0.05);
    outline: none;
    color: #000;
    cursor: pointer;
  }
  .width-unset {
    max-width: unset;
  }
  @media only screen and (max-width: 576px) {
    .sub-form {
      padding: 0 1em;
    }
  }
`;
