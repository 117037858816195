import React, { useMemo, useEffect } from "react";
import { useContext } from "react";
import AdminContext from "../../contexts/AdminContext";
import PriceContext from "../../contexts/PriceContext";
import ReservationContext from "../../contexts/ReservationContext";

const Price = ({ values, room }) => {
  const { admin } = useContext(AdminContext);
  const { setReservation } = useContext(ReservationContext);

  const { calculePrice } = useContext(PriceContext);
  const price = useMemo(
    () => calculePrice(values, room, admin),
    [values, room, admin]
  );
  useEffect(() => {
    if (price) {
      console.log("where here : ", values);
      setReservation({
        ...values,
        roomId: room.id,
        roomName: room.name,
        reservation_type: "formation",
      });
    }
  }, [price]);

  console.count("mounted");
  console.log(price);

  return (
    <div className="prix-container">
      <h1>Prix</h1>
      <p className="prix">{price} DH</p>
    </div>
  );
};

export default React.memo(Price);
