import React, { useContext } from "react";
import styled from "styled-components";
import ReservationContext from "../contexts/ReservationContext";
import PriceContext from "../contexts/PriceContext";

const NavigationButtons = ({
  step,
  setStep,
  status,
  send,
  dates,
  setError,
}) => {
  const { reservation } = useContext(ReservationContext);
  const { price } = useContext(PriceContext);
  const next = () => {
    if (step === 2) {
      send({ ...reservation, price });
    } else {
      setStep(step + 1);
    }
  };

  const back = () => {
    setStep(step - 1);
  };

  console.log("Nooo reservation : ", step);

  return (
    <Container step={step}>
      {(step !== 0 && step !== 1 && step !== 6 && step !== 3) ||
      (step === 3 &&
        status !== "Awaiting" &&
        reservation?.reservation_type != "conférence") ? (
        <button className="btn next" type="button" onClick={next}>
          {step === 6 ? "Envoyer" : "Suivant"}
        </button>
      ) : null}

      {step !== 0 && step !== 3 ? (
        <button type="button" className="btn back-btn" onClick={back}>
          Précédent
        </button>
      ) : null}
    </Container>
  );
};

export default NavigationButtons;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.step === 6 ? "row" : "row-reverse")};
  align-items: center;
  width: 100%;
  .btn {
    border-radius: 100px;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    background: #202862;
    padding: 8px 30px;
    border: 2px solid #202862;
    transition: all 0.5s;
    &:hover {
      color: #202862;
      background: transparent;
    }
  }

  .back-btn {
    background: #b8b8b8;
    color: #fff;
    border: 2px solid #b8b8b8;
    transition: all 0.5s;
    cursor: pointer;
    &:hover {
      color: #b8b8b8;
      background: transparent;
    }
  }

  @media only screen and (max-width: 5760px) {
    .btn {
      font-size: 13px;
      padding: 7px 24px;
    }
  }
`;
