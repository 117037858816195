import React, { useState, createContext } from "react";

const ReservationContext = createContext({});

export const ReservationProvider = ({ children }) => {
  const [reservation, setReservation] = useState(null);
  const reservationContext = {
    reservation,
    setReservation,
  };

  return (
    <ReservationContext.Provider value={reservationContext}>
      {children}
    </ReservationContext.Provider>
  );
};

export const Consumer = ReservationContext.Consumer;

export default ReservationContext;
