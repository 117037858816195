import React, { useState, useEffect } from "react";
import styled from "styled-components";
import jwt from "jsonwebtoken";
import { useHistory, useLocation } from "react-router-dom";
import Menu from "../components/Menu";
import Header from "../components/Header";
import { PriceProvider } from "../contexts/PriceContext";
import { ReactComponent as FikralabsSvg } from "../assets/fikralabs.svg";
import { ReservationProvider } from "../contexts/ReservationContext";

const DefaultLayout = ({ children }) => {
  const [menuIsActive, setMenuIsActive] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(0);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const admin = localStorage.getItem("userAuth");

    jwt.verify(admin, "d6d82b79-5226-454c-a36d-17bc13bcd6f2", (err) => {
      if (err) {
        history.push("/login");
      } else {
        setIsLoggedIn(1);
      }
    });
  }, []);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <ReservationProvider>
      <PriceProvider>
        <Container url={location.pathname}>
          <div className="menu-wrp">
            <Menu
              menuIsActive={menuIsActive}
              setMenuIsActive={setMenuIsActive}
            />
          </div>
          <div className="body">
            <Header
              setMenuIsActive={setMenuIsActive}
              menuIsActive={menuIsActive}
              path={location.pathname}
            />
            <div className="content">
              {React.cloneElement(children, { url: location.pathname })}
            </div>
            <div className="bottom-msg">
              <p className="p-left">
                All right reserved to Technopark Maroc, Made with love in Agadir
                by{" "}
              </p>
              <div>
                {" "}
                <FikralabsSvg />
                <p className="p-right">Fikralabs</p>
              </div>
            </div>
          </div>
        </Container>
      </PriceProvider>
    </ReservationProvider>
  );
};

export default DefaultLayout;

const Container = styled.div`
  display: flex;
  background: #e5e5e5;
  .menu-wrp {
    height: 100%;
    width: fit-content;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    z-index: 998;
  }
  .body {
    padding-left: 240px;
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
  }
  .content {
    padding: 1em;
    min-height: calc(100vh - 80px - 3em);
  }
  .bottom-msg {
    padding: 0.75em 1.25em;
    display: flex;
    flex-wrap: wrap;
    > div {
      display: flex;
    }
    .p-left {
      font-size: 13px;
      color: #202862;
    }
    svg {
      margin: 0 1em;
    }
    .p-right {
      font-size: 13px;
      color: #202862;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  @media only screen and (max-width: 768px) {
    display: flex;

    .body {
      padding: 0;
    }
    .menu-wrp {
      overflow-y: hidden;
      padding: 0em 0 5em;
    }
    .bottom-msg {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .p-left {
        font-size: 10px;
        margin-bottom: 0.5em;
      }
    }
  }
`;
