import React from "react";
import styled from "styled-components";

const PageTitle = ({ title }) => {
  return (
    <Container>
      <h3>{title}</h3>
    </Container>
  );
};

export default PageTitle;

const Container = styled.div`
  height: 40px;
  border-bottom: 5px solid #009685;
  h3 {
    font-size: 1.2rem;
    line-height: 29px;
    color: #202862;
    text-transform: capitalize;
  }
`;
