import React from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import logo from "../assets/logo.png";
import Button from "../components/elements/Button";
import PageTitle from "../components/elements/PageTitle";
import loginimg from "../assets/verefication.png";

const LoginFormComponent = () => {
  const history = useHistory();

  return (
    <Container>
      <div className="wrp">
        <div className="left-part">
          <img src={loginimg} alt="img" />
        </div>

        <div className="right-part">
          <PageTitle title="Valider le N° de téléphone" />
          <img src={logo} alt="logo" className="logo" />

          <p>
            Valider le n° de tél. Pour votre sécurité, Technopark souhaite
            s&apos;assurer qu&apos;il s&apos;agit bien de vous. Nous allons donc
            vous envoyer un code de validation à six chiffres par SMS.
          </p>
          <div className="btns">
            <Button
              handleClick={() => history.goBack()}
              title="Retour"
              type="submit"
              radius="7px"
              color="#bbb"
              font="14"
            />
            <Button
              handleClick={null}
              title="Confirmer"
              type="submit"
              radius="7px"
              color="#202862"
              font="14"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LoginFormComponent;

const Container = styled.div`
  background-color: #e5e5e5;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrp {
    background-color: #fff;
    width: 100%;
    max-width: 1100px;
    height: 100%;
    max-height: 700px;
    display: grid;
    grid-template-columns: 50% 50%;
    border-radius: 20px;
    overflow: hidden;
  }
  .left-part {
    width: 100%;
    img {
      max-height: 700px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .right-part {
    display: grid;
    grid-template-rows: 1fr 2fr 2fr 1fr 1fr;
    padding: 2em;
  }
  .logo {
    width: 100%;
    margin: auto;
    max-width: 140px;
  }
  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  p {
    color: #767676;
    font-size: 14px;
    text-align: center;
  }

  @media only screen and (max-width: 756px) {
    .wrp {
      grid-template-columns: 100% !important;
      max-height: unset;
    }
    .left-part {
      display: none;
    }
    .right-part {
      padding: 1em;
    }
  }
`;
