import React, { createContext, useState } from "react";

const StepContext = createContext({});

export const StepProvider = ({ children }) => {
  const [step, setStep] = useState(0);

  const stepContext = {
    step,
    setStep,
  };

  return (
    <StepContext.Provider value={stepContext}>{children}</StepContext.Provider>
  );
};

export const StepConsumer = StepContext.Consumer;

export default StepContext;
