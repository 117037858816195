import React, { useState, useEffect } from "react";
import styled from "styled-components";
import fr from "date-fns/locale/fr";
import { Calendar } from "react-date-range";
import "flatpickr/dist/themes/airbnb.css";

import firebase from "firebase/app";
import "firebase/firestore";
import Flatpickr from "react-flatpickr";
const ThirdStep = ({ setDate, date, error, setError, room }) => {
  const [fulldate, setFullDate] = useState(new Date());
  const [comment, setComment] = useState("");
  const [forceupdate, setForceupdate] = useState(false);

  const getEndate = (fromhr) => {
    //const number = Number(String(fromhr).split(":")[0]);
    //return isNaN(number) ? "all" : `${Number(number) + 5} h`;
  };

  //const toHeure = getEndate(from);

  const hours = [
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "Tout la journné",
  ];

  const periodeTime = ["Matinée", "Après midi", "Soir", "Toute la journée"];

  const Period = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <select
          onChange={(e) => setDate({ ...date, time: e.target.value })}
          className="hour"
          value={date.time}
        >
          <option value="">Séléctionnez une plage horaire</option>
          {periodeTime.map((item, index) => (
            <option value={item} key={index}>
              {item}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const renderTime = {
    Conférence: <Period />,
  };

  const getDate = (dates) => {
    const arr = [];
    dates.map((item) => {
      arr.push(item.from);
      arr.push(item.to);
    });
    arr.sort();
    const from = new Date(arr[0]).toLocaleDateString("fr-CA");
    const to = new Date(arr[arr.length - 1]).toLocaleDateString("fr-CA");

    //const str = "De " + from + " à " + to;
    //return str;
  };

  const handleDate = (date) => {
    const d = date.toLocaleDateString("fr-Fr", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    //setDate(d);
    //setFullDate(date);
    console.log(d);
  };

  const add = () => {
    const arr1 = [];
    const arr2 = [];
    setError(0);
    let dTo = new Date(fulldate);
    let dFrom = new Date(fulldate);
    dTo.setHours(to.split(":")[0], to.split(":")[1], "0");
    dFrom.setHours(from.split(":")[0], to.split(":")[1], "0");

    dTo = firebase.firestore.Timestamp.fromDate(dTo);
    dFrom = firebase.firestore.Timestamp.fromDate(dFrom);

    arr1.push({
      date,
      from: from.replace(":", "h"),
      to: to.replace(":", "h"),
      comment,
    });

    arr2.push({
      from: dFrom,
      to: dTo,
      comment,
    });

    setState(arr2);
    setDates(arr1);
    setForceupdate(!forceupdate);
  };

  return (
    <Container>
      <div className="datepicker-container">
        <div className="hours-container">
          <Flatpickr
            options={{ minDate: new Date() }}
            style={{ width: "100%" }}
            placeholder="Choissisez une date"
            onChange={(e, dateStr) => {
              setDate({ ...date, day: dateStr });
            }}
          />
          {renderTime[room?.type]}
          <h4>Commentaire:</h4>
          <textarea
            value={date.comment}
            onChange={(e) => setDate({ ...date, comment: e.target.value })}
          />
          <br />
        </div>
      </div>
      <div className="table">
        <h5 className="label">Date</h5>
        <h5 className="label">Horaire</h5>
        <h5 className="label">Commentaire</h5>
      </div>
      <p className="error">{error ? "doit contenir une date" : null}</p>
      <div className="row-table">
        <h6 className="date-h6">{date.day}</h6>
        <h6 className="date-h6">{date.time}</h6>
        <h6 className="date-h6">{date.comment}</h6>
      </div>
    </Container>
  );
};

export default ThirdStep;

const Container = styled.div`
  .table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1em;
    width: 100%;
  }
  background: #fff;
  border-radius: 20px;
  width: 100%;
  margin: auto;
  h3 {
    color: #202862;
    font-weight: 600 !important;
    margin-top: 1em;
  }
  h4 {
    color: #202862;
    font-weight: 600 !important;
    margin-top: 2.25em;
  }
  .datepicker-container {
    display: grid;
    grid-template-columns: 340px auto;
    grid-gap: 2em;
    margin-bottom: 2em;
  }
  .calandar-container {
    max-width: 340px;
  }
  .hour {
    width: 100%;
  }
  .hour,
  textarea {
    color: #838fe8;
    border-radius: 7px;
    border: 1px solid #838fe8;
    padding: 8px;
    margin-top: 0.75em;
    outline: none;
    font-size: 15px;
  }
  textarea {
    height: 80px;
    width: 330px;
  }
  label {
    font-size: 15px;
    font-weight: 500;
    margin: 0 1em;
    color: #202862;
  }
  .submit {
    background-color: #202862;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    border-radius: 7px;
    margin-top: 0.25em;
    padding: 6px 14px;
    transition: all 0.3s;
    border: 2px solid #202862;
    &:hover {
      color: #202862;
      background-color: transparent;
    }
  }
  .row {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .date-h6 {
    margin: 0.3em 0;
    font-size: 16px;
    font-weight: 500;
    color: #202862;
    padding-bottom: 0.5em;
    border-bottom: 1px solid #f4f4f4;
  }
  .label {
    padding-bottom: 0.5em;
    border-bottom: 1px solid #f1f1f1;
    color: #3b4f64;
    font-size: 15px;
    font-weight: 600;
    margin: 0.3em 0;
    text-transform: capitalize;
  }
  .error {
    font-size: 12px;
    color: red;
  }

  .row-table {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1em;
    h5 {
      margin-right: 10px;
    }
  }
`;
