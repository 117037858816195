import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import firebase from "firebase/app";
import "firebase/firestore";
import Layout from "../layouts/DefaultLayout";
import PageTitle from "../components/elements/PageTitle";
import AdminContext from "../contexts/AdminContext";
import ReservationCart from "../components/ReservationCart";

const Reservations = () => {
  const [activeResvs, setActiveResvs] = useState([]);
  const [notActiveResvs, setNotActiveResvs] = useState([]);
  const db = firebase.firestore();
  let isMounted = true;
  const { admin } = useContext(AdminContext);

  const getReservations = async () => {
    const tempArray1 = [];
    const tempArray2 = [];
    await db
      .collection("reservations")
      .where("userId", "==", admin.id)
      .orderBy("createdAt", "desc")
      .get()
      .then(async (snapshot) => {
        await Promise.all(
          snapshot.docs.map(async (doc) => {
            const item = doc.data();
            item.id = doc.id;
            item.dates.map((item) => {
              item.from = item.from.toDate().getTime();
              item.to = item.to.toDate().getTime();
            });
            item.date = getDate(item.dates);

            await db
              .collection("users")
              .doc(item.userId)
              .get()
              .then((doc) => {
                const user = doc.data();
                user.birthDate = user.birthDate.toDate().getTime();
                item.user = user;
              })
              .catch((e) => {
                console.log(e);
              });

            await db
              .collection("rooms")
              .doc(item.roomId)
              .get()
              .then((doc) => {
                const room = doc.data();
                item.roomImg = room.images[0];
                item.roomName = room.name;
                item.roomType = room.type;
                item.roomNbr = room.places;
              })
              .catch((e) => {
                console.log(e);
              });
            if (item.status === "Awaiting") {
              tempArray1.push(item);
            } else {
              tempArray2.push(item);
            }
          })
        );
        if (isMounted) {
          setActiveResvs(tempArray1);
          setNotActiveResvs(tempArray2);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getDate = (dates) => {
    const arr = [];
    dates.map((item) => {
      arr.push(item.from);
      arr.push(item.to);
    });
    arr.sort();
    const from = new Date(arr[0]).toLocaleDateString("fr-CA");
    const to = new Date(arr[arr.length - 1]).toLocaleDateString("fr-CA");

    const str = "De " + from + " à " + to;
    return str;
  };

  useEffect(() => {
    if (isMounted && admin?.id) {
      getReservations();
    }
    return () => {
      isMounted = false;
    };
  }, [admin]);

  return (
    <Layout>
      <Container>
        <PageTitle title="Liste de réservations" />
        <h4>Réservations encore de traitement</h4>
        {activeResvs.map((item, index) => (
          <ReservationCart data={item} key={index} />
        ))}
        <h4>Réservations traiteés</h4>
        {notActiveResvs.map((item, index) => (
          <ReservationCart data={item} key={index} />
        ))}
      </Container>
    </Layout>
  );
};

export default Reservations;

const Container = styled.div`
  background: #fff;
  border-radius: 20px;
  width: 100%;
  padding: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h3,
  div {
    width: 100%;
  }
  h4 {
    margin: 3em 0 1em 0;
    color: #202862;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    text-transform: capitalize;
  }
`;
