import React, { useState } from "react";
import styled from "styled-components";
import Conference from "./conference";
import Formation from "./formation";
import Reunion from "./reunion";

const SecondStep = ({
  room,
  setNeeds,
  needs,
  setType,
  setThematique,
  type,
  thematique,
  tools,
  setDate,
  date,
  error,
  setError,
  customType,
  setCustomType,
}) => {
  console.log("Well this is the  : ", room);
  return (
    <Container>
      <div className="content">
        <div className="col">
          <h3 className="title">Détails du salle:</h3>
          <div className="details-room">
            <img src={room?.images[0]} alt="room" className="img" />
            <div className="details">
              <h1 style={{ fontSize: 19, marginBottom: 10 }}>{room?.name}</h1>

              <div className="row-first">
                <div className="row">
                  <h5>Capacité:</h5>
                  <h6>{room?.capacite}</h6>
                </div>
                <div className="row">
                  <h5>Chaises:</h5>
                  <h6>{room?.chaises}</h6>
                </div>
              </div>
              <div className="row-first">
                <div className="row">
                  <h5>Tables:</h5>
                  <h6>{room?.tables}</h6>
                </div>
                <div className="row">
                  <h5>Ville:</h5>
                  <h6>{room?.ville}</h6>
                </div>
              </div>
              <div className="row-first">
                <div className="row">
                  <h5>Climatisation:</h5>
                  <h6>{room?.climatisation}</h6>
                </div>
                <div className="row">
                  <h5>Dimensions:</h5>
                  <h6>{room?.dimensions}</h6>
                </div>
              </div>
            </div>
          </div>

          {room.type === "Conférence" ? (
            <Conference room={room} tools={tools} />
          ) : room.type === "Réunion" ? (
            <Reunion room={room} tools={tools} />
          ) : (
            <Formation room={room} tools={tools} />
          )}
        </div>
      </div>
    </Container>
  );
};

export default SecondStep;

const Container = styled.div`
  .details-room {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  background: #fff;
  border-radius: 20px;
  width: 100%;
  padding: 1.5em;
  .details {
    width: 100%;
    margin-left: 10px;
    padding: 10px;
  }
  .row-first {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5em;
    margin: auto;
    margin-top: 2em;
    min-height: calc(100vh - 180px - 17em) !important;
  }
  .row {
    width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    h5 {
      margin-right: 10px;
    }
  }
  .row-table {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1em;
    h5 {
      margin-right: 10px;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 0.5fr 40px;
    grid-gap: 1em;
  }
  .grid-bottom {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    color: #202862;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 1.25em;
    margin-top: 1em;
    height: 30px;
    border-bottom: 4px solid #009685;
    width: fit-content !important;
  }
  .second {
    margin-top: 2em;
  }
  .img {
    width: 70%;
    border-radius: 20px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    max-width: 450px;
    max-height: 300px;
    object-fit: cover;
  }
  h6 {
    color: #202862;
    font-size: 15px;
    font-weight: 500;
    margin: 0.3em 0;
    text-transform: capitalize;
  }
  h5 {
    color: #009685;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    text-transform: capitalize;
  }
  .date-row {
    display: flex;
    align-items: center;
  }
  .date-row h6 {
    margin-top: 2.15em;
    margin-left: 1em;
  }
  .date {
    color: red;
    font-weight: 600;
  }
  .label {
    padding-bottom: 0.5em;
    border-bottom: 1px solid #f1f1f1;
  }
  .date-h6 {
    font-size: 13px;
  }
  input,
  select {
    color: #838fe8;
    border-radius: 7px;
    border: 1px solid #838fe8;
    padding: 8px;
    outline: none;
  }
  .submit {
    background-color: #202862;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    border-radius: 7px;
    padding: 4px;
    width: 50px;
    transition: all 0.3s;
    border: 2px solid #202862;
    &:hover {
      color: #202862;
      background-color: transparent;
    }
  }
  .red {
    font-size: 10px;
    color: red;
    font-weight: 600;
    margin-top: 0.5em;
  }
  @media only screen and (max-width: 1280px) {
    .content {
      grid-template-columns: 1fr;
    }
  }
  @media only screen and (max-width: 900px) {
    padding: 0;
    .details-room {
      flex-direction: column;
    }
    .img {
      width: 100%;
    }
  }
  @media only screen and (max-width: 576px) {
    .title {
      font-size: 16px;
    }
  }
`;
