import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Logo from "../assets/logo.png";
import { ReactComponent as HomeSvg } from "../assets/event.svg";
import { ReactComponent as ListSvg } from "../assets/list.svg";
import { ReactComponent as ProfileSvg } from "../assets/account.svg";
import StepContext from "../contexts/StepContext";

const Menu = ({ menuIsActive, setMenuIsActive }) => {
  const { setStep } = useContext(StepContext);

  return (
    <Container menuIsActive={menuIsActive}>
      <div className="logo-wrapper">
        <NavLink to="/" onClick={() => setMenuIsActive(!menuIsActive)}>
          <img src={Logo} alt="logo" className="logo" />
        </NavLink>
      </div>
      <div className="dash-links">
        <NavLink
          exact
          activeClassName="active-link"
          className="dash-link"
          to="/"
          onClick={() => {
            setMenuIsActive(!menuIsActive);
            setStep(0);
          }}
        >
          <HomeSvg />
          <span>Réservé une salle</span>
        </NavLink>
        <NavLink
          activeClassName="active-link"
          className="dash-link"
          to="/liste-reservations"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <ListSvg />
          <span>Liste de réservations</span>
        </NavLink>
        <NavLink
          activeClassName="active-link"
          className="dash-link"
          to="/profile"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <ProfileSvg />
          <span>Profile</span>
        </NavLink>
      </div>
    </Container>
  );
};

export default Menu;

const Container = styled.div`
  padding: 2em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: #fff;
  transition: all 0.3s;
  overflow-x: hidden;
  height: 100%;
  width: 240px;
  z-index: 999;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  .logo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 0 1.7em 0;
    width: 100%;
    min-width: 90px;
  }
  .logo {
    width: 100%;
    max-width: 140px;
    height: 100%;
    cursor: pointer;
  }
  .dash-links {
    padding: 1em 0;
    width: 100%;
  }
  .dash-link {
    padding: 1.1em 1.5em;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #e0e0e0;
    svg {
      margin-bottom: 0.5em;
      transition: all 0.4s;
      min-width: 24px;
    }
    span {
      font-size: 14px;
      font-weight: 500 !important;
      color: #202862;
      transition: all 0.4s;
      white-space: nowrap;
    }
  }
  .active-link {
    background: #009685;
    span {
      color: #fff;
    }
    svg {
      path {
        fill: #fff;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    position: relative;
    width: ${(props) => (props.menuIsActive ? "240px" : "0")};
    min-height: ${(props) =>
      props.menuIsActive ? "calc(100vh - 80px)" : "100vh"};
    top: ${(props) => (props.menuIsActive ? "80px" : "0")};
  }
`;
