import ReservationContext from "../../contexts/ReservationContext";
import React, { useContext, useEffect } from "react";

const AutoSubmit = ({ room, values, needs }) => {
  const { reservation, setReservation } = useContext(ReservationContext);
  useEffect(() => {
    if (values) {
      setReservation({
        ...values,
        roomId: room.id,
        roomName: room.name,
        needs,
        reservation_type: "conférence",
      });
    }
  }, [values, room, needs]);
  return <></>;
};

export default React.memo(AutoSubmit);
