import React, { useContext } from "react";
import { Form, Formik, Field } from "formik";
import styled from "styled-components";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import Price from "./price";

import * as Yup from "yup";
import { French } from "flatpickr/dist/l10n/fr";

const periodeTime = [
  {
    id: 1,
    name: "8h à 12h",
  },
  {
    id: 2,
    name: "14h à 18h",
  },
  {
    id: 3,
    name: "Soir",
  },
  {
    id: 4,
    name: "Toute la journée",
  },
];

const Formation = ({ room }) => {
  return (
    <Container>
      <Formik
        initialValues={{
          time: 1,
          day: new Date().toLocaleDateString("fr"),
          fullDate: new Date(),
          comment: "",
          format: "Format U",
          nbr: "",
          climatisation: "oui",
        }}
        validationSchema={Yup.object({
          day: Yup.string().required("Champs Obligatoire"),
          time: Yup.string().required("Champs Obligatoire"),
          format: Yup.string().required("Champs Obligatoire"),
          climatisation: Yup.string().required("Champs Obligatoire"),
          nbr: Yup.number()
            .max(
              room.capacite,
              `vous avez dépassé le nombre maximum de places ${room.capacite}`
            )
            .required("Champs Obligatoire"),
        })}
        style={{ width: "100%" }}
      >
        {({ setFieldValue, errors, touched, values }) => {
          console.log(values);
          return (
            <Form>
              <h3 className="title">Date de réservation:</h3>
              <div className="row-table">
                <div className="hours-container">
                  <Flatpickr
                    options={{ minDate: new Date(), locale: French }}
                    style={{ width: "100%", marginTop: 0, marginBottom: 10 }}
                    placeholder={new Date().toLocaleDateString("fr")}
                    name="day"
                    onChange={(date, dateStr) => {
                      setFieldValue("day", dateStr);
                      setFieldValue("fullDate", date);
                    }}
                  />
                  {errors.day && touched.day && <p>{errors.day}</p>}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Field name="time" as="select" className="hour">
                      {periodeTime.map((item, index) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                  {errors.time && touched.time && (
                    <p className="red">{errors.time}</p>
                  )}
                  <h4>Commentaire:</h4>
                  <Field className="textarea" as="textarea" name="comment" />
                  <br />
                </div>
              </div>
              <h3 className="title">Besoins:</h3>
              <div className="row">
                <h5>Format de la salle</h5>
                <Field as="select" name="format">
                  <option value="Format U">Format U</option>
                  <option value="Atelier">Atelier</option>
                  <option value="Classe">Classe </option>
                </Field>
                {errors.format && touched.format && (
                  <p className="red">{errors.format}</p>
                )}
              </div>
              <div className="row">
                <h5>Nombre de participants</h5>
                <Field type="number" name="nbr" />
              </div>
              {errors.nbr && touched.nbr && <p className="red">{errors.nbr}</p>}
              <div className="row">
                <h5>Climatisation</h5>
                <Field as="select" name="climatisation">
                  <option value="oui">Oui</option>
                  <option value="non">Non</option>
                </Field>
              </div>
              {errors.climatisation && touched.climatisation && (
                <p className="red">{errors.climatisation}</p>
              )}

              <Price values={values} room={room} />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default Formation;

const Container = styled.div`
  .textarea {
    color: #838fe8;
    border-radius: 7px;
    border: 1px solid #838fe8;
    padding: 8px;
    margin-top: 0.75em;
    outline: none;
    font-size: 15px;
  }
  .textarea {
    height: 80px;
    width: 330px;
  }
  h4 {
    color: #202862;
    font-weight: 600 !important;
    margin-top: 2.25em;
  }
  .prix-container {
    width: 100%;
    margin-top: 10px;
    padding: 10px 0;
    border-top: 2px solid #202862;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 20px;
    }
    .prix {
      color: #202862;
      font-size: 20px;
      font-weight: bold;
    }
  }
`;
